import Header from './components/Header';
import HomePage from './pages/HomePage';
import Footer from "./components/Footer";
import { GoHomeArrow } from './components/GoHomeArrow';

function App() {
    return (
        <div className="app-wrapper">
            <Header />

            <HomePage />

            <Footer />

            <GoHomeArrow />
        </div>
    );
}

export default App;
