type FooterContentType = {
    addressTitle: string,
    addressText: string,
    contactsTitle: string,
    emailText: string,
    phoneText: string,
    linksTitle: string,
    feedbackTitle: string,
    feedbackText: string,
    iframeSrc: string,
    copyrightText: string,
    facebookLink: string
}

export const FooterContent: FooterContentType = {
    addressTitle: "Address",
    addressText: "Moscow, Russian Federation, ulitsa Smolnaya 24A, office 703",
    contactsTitle: "Contacts",
    emailText: "Email: office@qnium.com",
    phoneText: "Phone: +7 (495) 988 07 64",
    linksTitle: "Links",
    feedbackTitle: "Feedback",
    feedbackText: "Please send us your feedback, we will be happy to talk to you and discuss your ideas about improvements to our services",
    iframeSrc: "https://www.google.com/maps/embed/v1/place?key=AIzaSyA0Dx_boXQiwvdz8sJHoYeZNVTdoWONYkU&q=place_id:ChIJX9J83Rs4tUYRwstac474Nwc",
    copyrightText: '© Copyright 2023 QNIUM LLC all rights reserved',
    facebookLink: 'https://www.facebook.com/QNIUM-1919259821622649/'
}