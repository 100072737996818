import { useRef } from 'react';
import styles from './MultiItemsCarousel.module.css';
import { Image } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import { Fade } from 'react-awesome-reveal';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

type MultiItemsCarouselProps = {
    items: string[],
    autoplayInterval?: number
}

function MultiItemsCarousel(props: MultiItemsCarouselProps) {
    let carouselRef = useRef<Carousel | null>(null);

    const responsive = {
        desktop: {
            breakpoint: { max: Number.MAX_VALUE, min: 992 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 992, min: 768 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    };

    return (
        <Fade direction='up' triggerOnce cascade>
            <div>
                <Carousel
                    responsive={responsive}
                    autoPlay={props.autoplayInterval !== undefined} autoPlaySpeed={props.autoplayInterval}
                    infinite
                    ref={carouselRef}
                    arrows={false}
                >
                    {props.items.map((client, ind) => <Image key={ind} style={{maxWidth: '100%'}} draggable={false} src={client} />)}
                </Carousel>
            </div>
            <div className='d-flex justify-content-center mt-4'>
                <button className={styles.carouselButton} onClick={() => carouselRef.current?.previous(1)}><BsArrowLeftShort /></button>
                <button className={styles.carouselButton} onClick={() => carouselRef.current?.next(1)}><BsArrowRightShort /></button>
            </div>
        </Fade>
    );
}

export default MultiItemsCarousel;