import { ReactNode } from "react";

type HomePageContentType = {
    homeSection: SectionContentType,
    homeSectionButtonText: string,
    servicesSection: SectionContentType,
    clientsSection: SectionContentType,
    projectsSection: SectionContentType,
}

type SectionContentType = {
    title: string,
    subtitle: ReactNode
}

export const HomePageContent: HomePageContentType = {
    homeSection: {
        title: "Software development service you were searching for",
        subtitle: <>Custom mobile, web and desktop applications for startups and enterprises<br/>Software from the authors of Atomity Framework - the most advanced framework for software systems development<br/><br/>More than 50 projects since 2010<br/></>
    },
    homeSectionButtonText: 'Request a quote',
    servicesSection: {
        title: "Our services",
        subtitle: <>Partner with us to create modern IT systems,<br/>Cutting-edge technologies in most promising directions of business and industry</>
    },
    clientsSection: {
        title: "Our customers",
        subtitle: <>Find verified reviews at <a href="https://clutch.co/profile/qnium">clutch.co</a></>
    },
    projectsSection: {
        title: "Our projects",
        subtitle: <>Take a look at what we already did for our amazing customers</>
    }
}