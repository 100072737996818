import { createContext } from "react";
import { RequestQuoteDialog, RequestQuoteDialogRef } from "../dialogs/RequestQuoteDialog";

export interface IDialogsService {
    RequestQuoteDialog: RequestQuoteDialogRef | undefined
}

export const DialogsServiceContext = createContext<IDialogsService | undefined>(undefined);

function DialogsService({children}: any) {

    const dialogsService: IDialogsService = {
        RequestQuoteDialog: undefined
    }

    return(
        <DialogsServiceContext.Provider value={dialogsService}>
            {children}
            {/* all dialogs should be presented below */}
            <RequestQuoteDialog ref={(ref: any) => dialogsService.RequestQuoteDialog = ref} />
        </DialogsServiceContext.Provider>
    )
}

export default DialogsService;