import { useEffect, useState } from "react";
import { BsArrowUpShort } from 'react-icons/bs';
import { Sections } from "../definitions/Sections";
import styles from './GoHomeArrow.module.css';

export function GoHomeArrow() {
    const [isScrollTop, setIsScrollTop] = useState(true);

    useEffect(() => {
        const handler = () => {
            setIsScrollTop((isScrollTop) => {
                if (isScrollTop && document.documentElement.scrollTop > 5) {
                    return false;
                }

                if (!isScrollTop && document.documentElement.scrollTop < 5) {
                    return true;
                }

                return isScrollTop;
            });
        };

        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
    }, []);

    const opacityValue = (value: boolean) => value ? '0' : '';

    return (<a className={styles.goHomeArrow} style={{opacity: opacityValue(isScrollTop)}} href={`/#${Sections.home}`}><BsArrowUpShort /></a>);
}