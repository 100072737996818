import { Sections } from "../definitions/Sections";
import { BsBriefcase } from 'react-icons/bs';
import { SlPeople, SlMagicWand } from 'react-icons/sl';
import { IconType } from "react-icons";

type FeatureContentType = {
    icon: IconType,
    title: string,
    text: string,
    linkText: string,
    linkTo: string
}

export const FeaturesContent: FeatureContentType[] = [
    {
        icon: SlPeople,
        title: 'Who we are',
        text: 'We are software development company applying deep engineering approach during development of software systems',
        linkText: 'learn more..',
        linkTo: Sections.contacts
    },
    {
        icon: SlMagicWand,
        title: 'What we do',
        text: 'We develop awesome software products, mobile and web apps, SaaS services',
        linkText: 'learn more..',
        linkTo: Sections.services
    },
    {
        icon: BsBriefcase,
        title: 'Who are our clients',
        text: 'Technology startups\nEstablished companies\nEntrepreneurs\nNon-profit organizations',
        linkText: 'learn more..',
        linkTo: Sections.clients
    },
]