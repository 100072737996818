import { Col, Container, Row } from "react-bootstrap";
import { Sections } from "../definitions/Sections";
import { SectionNames } from "../content/SectionNames";
import { FooterContent } from "../content/FooterContent";
import { Fade } from "react-awesome-reveal";

import { FaFacebookF } from 'react-icons/fa';

function Footer() {
    return (
        <footer className='footer text-center text-md-start' id={Sections.contacts}>
            <Container>
                <Fade direction="up" triggerOnce>
                    <Row>
                        <Col md={3}>
                            <p className="mb-0">
                                <strong>{FooterContent.addressTitle}</strong>
                                <br />
                                <br />{FooterContent.addressText}<br />
                                <br />
                                <br /><strong>{FooterContent.contactsTitle}</strong>
                                <br />
                                <br />{FooterContent.emailText}
                            </p>
                            <p>{FooterContent.phoneText}</p>
                        </Col>
                        <Col md={3}>
                            <p>
                                <strong>{FooterContent.linksTitle}</strong>
                                <br />
                                <br />
                                <a href={`/#${Sections.services}`} className="primary-link">{SectionNames.services}</a>
                                <br />
                                <a href={`/#${Sections.projects}`} className="primary-link">{SectionNames.projects}</a>
                                <br />
                                <a href={`/#${Sections.clients}`} className="primary-link">{SectionNames.clients}</a>
                                <br />
                                <br />
                                <strong>{FooterContent.feedbackTitle}</strong>
                                <br />
                                <br />
                                {FooterContent.feedbackText}
                            </p>
                        </Col>
                        <Col md={6}>
                            <div className="google-map"><iframe title="google map" style={{ border: 0 }} src={FooterContent.iframeSrc}></iframe></div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <p>{FooterContent.copyrightText}</p>
                        </Col>
                        <Col md={6} className='socials justify-content-md-end'>
                            <a href={FooterContent.facebookLink} target="_blank" rel="noreferrer">
                                <FaFacebookF />
                            </a>
                        </Col>
                    </Row>
                </Fade>
            </Container>
        </footer>
    );
}

export default Footer;