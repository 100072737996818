import React, { useContext } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Fade } from 'react-awesome-reveal';
import MultiItemsCarousel from "../components/common/MultiItemsCarousel";
import Jarallax from "../components/common/Jarallax";
import { HomePageContent } from "../content/HomePageContent";
import { Sections } from "../definitions/Sections";
import { ClientsImageSrc } from "../definitions/ClientsImageSrc";
import { ProjectsContent } from "../content/ProjectsContent";
import { FeaturesContent } from "../content/FeaturesContent";
import { ServicesContent } from "../content/ServicesContent";
import { DialogsServiceContext } from "../services/DialogsService";
import { RequestQuoteType } from "../services/RequestService";

import { TbDeviceMobileMessage } from 'react-icons/tb';
import { BsArrowDownShort } from 'react-icons/bs';

import parallaxHomeSectionImage from '../resources/mbr-1920x1079.jpg';
import parallaxFeaturesSectionImage from '../resources/mbr-1620x1080.jpg';
import parallaxClientsSectionImage from '../resources/mbr-1-1920x1280.jpg';

function HomePage() {
    const dialogsService = useContext(DialogsServiceContext);

    return (
        <div>
            <section className="home-section overflow-hidden" id={Sections.home}>
                <Container className="align-center">
                    <Row className="justify-content-md-center">
                        <Col md={10}>
                            <Fade direction='up' triggerOnce cascade>
                                <h1 className="pb-3">{HomePageContent.homeSection.title}</h1>

                                <p className="pb-3">{HomePageContent.homeSection.subtitle}</p>
                                <Button className="btn btn-white-outline" onClick={() => dialogsService?.RequestQuoteDialog?.open(RequestQuoteType.RequestAQuote, HomePageContent.homeSectionButtonText)}><span className="d-flex align-items-center"><TbDeviceMobileMessage size={26} className='me-2' />{HomePageContent.homeSectionButtonText}</span></Button>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                <Jarallax options={{ imgSrc: parallaxHomeSectionImage }}>
                    <div className='parallax-overlay-opacity-50'></div>
                </Jarallax>
                <a className='go-features-arrow' href={`/#${Sections.features}`}><BsArrowDownShort /></a>
            </section>

            <section id={Sections.features} style={{padding: '5.5rem 0'}}>
                <Container>
                    <Row className="justify-content-center">
                        {FeaturesContent.map((feature, index) =>
                            <Col md={6} lg={4} key={index} className="features-card p-3 d-flex">
                                <div className="pe-3">
                                    <feature.icon size={65} style={{ color: 'var(--link-text-color)' }} />
                                </div>
                                <div>
                                    <Fade direction='up' triggerOnce>
                                        <h4 className="py-3">{feature.title}</h4>
                                        <div>{feature.text}<br/>
                                            <a href={`/#${feature.linkTo}`} className="primary-link">{feature.linkText}</a>
                                        </div>
                                    </Fade>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>

                <Jarallax options={{ imgSrc: parallaxFeaturesSectionImage }}>
                    <div className='parallax-overlay-opacity-80'></div>
                </Jarallax>
            </section>

            <section className="dark-text text-center" id={Sections.services}>
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={8}>
                            <Fade direction='up' triggerOnce>
                                <h2 className='pb-3'>{HomePageContent.servicesSection.title}</h2>
                                <h3 className="secondary-text subtitle-text">{HomePageContent.servicesSection.subtitle}</h3>
                            </Fade>
                        </Col>
                    </Row>

                    {ServicesContent.map((servicesRow, index) =>
                        <Row key={index} className='justify-content-center' style={{paddingTop: '5rem'}}>
                            {servicesRow.map((service, ind) =>
                                <Col md={6} lg={4} key={ind} className='features-card'>
                                    <service.icon width={100} height={100} className='my-3 primary-icon' />
                                    <Fade direction='up' triggerOnce>
                                        <h3>{service.title}</h3>
                                        <p className="secondary-text">{service.text}</p>
                                    </Fade>
                                </Col>
                            )}
                        </Row>
                    )}
                </Container>
            </section>

            <section className="dark-text text-center" id={Sections.clients}>
                <Container>
                    <Row className='justify-content-center mb-5'>
                        <Col md={8}>
                            <Fade direction='up' triggerOnce>
                                <h2 className='pb-3'>{HomePageContent.clientsSection.title}</h2>
                                <h3 className="secondary-text subtitle-text">{HomePageContent.clientsSection.subtitle}</h3>
                            </Fade>
                        </Col>
                    </Row>

                    <MultiItemsCarousel items={ClientsImageSrc} autoplayInterval={5000} />
                </Container>

                <Jarallax options={{ imgSrc: parallaxClientsSectionImage }}>
                    <div className='parallax-bright-overlay'></div>
                </Jarallax>
            </section>

            <section className="dark-text text-center" id={Sections.projects}>
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={8}>
                            <Fade direction='up' triggerOnce>
                                <h2 className='pb-3'>{HomePageContent.projectsSection.title}</h2>
                                <h3 className="secondary-text subtitle-text">{HomePageContent.projectsSection.subtitle}</h3>
                            </Fade>
                        </Col>
                    </Row>
                    {ProjectsContent.map((projectsRow, index) =>
                        <Row key={index} className="justify-content-center">
                            {projectsRow.map((project, ind) =>
                                <Col md={6} lg={4} key={ind}>
                                    <Fade direction='up' triggerOnce className='mt-5 projects-card'>
                                        <Image src={project.imageSrc} />
                                        <p className="secondary-text">{project.text}</p>
                                    </Fade>
                                </Col>
                            )}
                        </Row>
                    )}
                </Container>
            </section>
        </div>
    );
}

export default HomePage;