type HeaderContentType = {
    contactWithUsButtonText: string,
    changeLocaleButtonText: string,
    changeLocaleHref: string
}

export const HeaderContent: HeaderContentType = {
    contactWithUsButtonText: "Contact Us",
    changeLocaleButtonText: "Rus",
    changeLocaleHref: "https://qnium.ru/"
}