export const Sections: SectionType = {
    home: 'home',
    features: 'features',
    services: 'services',
    clients: 'clients',
    projects: 'projects',
    contacts: 'contacts'
}

export type SectionType = {
    home: string,
    features: string,
    services: string,
    clients: string,
    projects: string,
    contacts: string
}
