import React, { useImperativeHandle, useState } from "react";
import { Modal, Form, Spinner, Alert } from "react-bootstrap";
import { BsCheck2, BsX } from 'react-icons/bs';
import { RequestQuoteType, SendRequest } from "../services/RequestService";

const RequestQuoteDialogContent = {
    name: "Name",
    namePlaceholder: "Please enter your name",
    email: "Email",
    emailPlaceholder: "Please enter your email",
    details: "Details",
    detailsPlaceholder: "Please provide details of the request",
    sendRequestButtonText: "Send request",
    successfullySent: "Your request has been successfully sent!",
    failedToSendRequest: "Error: Failed to send the request!",
    somethingWentWrong: "Error: Something went wrong!"
}

type RequestQuoteDialogState = {
    show?: boolean,
    name?: string,
    email?: string,
    details?: string,
    header?: string,
    type?: RequestQuoteType,
    isLoading?: boolean,
    loaded?: boolean,
    errorMsg?: string
}

const initState: RequestQuoteDialogState = {
    show: false, name: '', email: '', details: '', header: '', isLoading: false, loaded: false, errorMsg: ''
}

export type RequestQuoteDialogRef = {
    open: (type: RequestQuoteType, header: string) => {}
    close: () => {}
}

export const RequestQuoteDialog = React.forwardRef((props, ref) => {
    const [dialogState, setDialogState] = useState({ ...initState } as RequestQuoteDialogState);

    const handleRequest = async(event: React.FormEvent) => {
        event.preventDefault();
        if (!dialogState.type) {
            errorHappened(RequestQuoteDialogContent.somethingWentWrong);
        } else if (dialogState.name && dialogState.email && dialogState.details)
        {
            startLoading();
            if (await SendRequest({ type: dialogState.type, name: dialogState.name??'', email: dialogState.email??'', details: dialogState.details??'' }))
                endLoading();
            else
                errorHappened(RequestQuoteDialogContent.failedToSendRequest, { isLoading: false });
        }
    }

    useImperativeHandle(ref, () => ({
        open: (type: RequestQuoteType, header: string) => {
            show({ ...initState, type, header });
        },
        close: () => {
            hide();
        }
    }));

    const setName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDialogState((curState) => ({ ...curState, name: event.target.value }));
    const setEmail = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDialogState((curState) => ({ ...curState, email: event.target.value }));
    const setDetails = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setDialogState((curState) => ({ ...curState, details: event.target.value }));
    const show = (updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, show: true }));
    const hide = (updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, show: false }));
    const startLoading = (updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, isLoading: true }));
    const endLoading = (updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, isLoading: false, loaded: true }));
    const errorHappened = (message: string, updState?: RequestQuoteDialogState) => setDialogState((curState) => ({ ...curState, ...updState, errorMsg: message }));

    return (
        <Modal className="request-quote-dialog" ref={ref} show={dialogState.show} centered onHide={hide}>
            <Modal.Header className="px-4" style={{ borderBottom: 0, fontWeight: 300 }}>
                <Modal.Title><div>{dialogState.header}</div></Modal.Title>
                <button className="modal-close" onClick={() => hide()}><BsX/></button>
            </Modal.Header>
            <Modal.Body className='px-4 pb-3 pt-0'>
                {dialogState.loaded ?
                    <div className="text-center pt-4 pb-3"><BsCheck2 size={30} className='me-2' style={{color: 'green'}} />{RequestQuoteDialogContent.successfullySent}</div>
                :
                    <Form className={`${dialogState.isLoading ? 'invisible' : ''}`} onSubmit={handleRequest}>
                        {dialogState.errorMsg ? <Alert variant='danger'>{dialogState.errorMsg}</Alert> : null}
                        <Form.Group className='mb-3'>
                            <Form.Label>{RequestQuoteDialogContent.name}<span style={{color: 'var(--bs-danger)'}}>*</span></Form.Label>
                            <Form.Control placeholder={RequestQuoteDialogContent.namePlaceholder} value={dialogState.name} onChange={setName} required />
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>{RequestQuoteDialogContent.email}<span style={{color: 'var(--bs-danger)'}}>*</span></Form.Label>
                            <Form.Control type='email' placeholder={RequestQuoteDialogContent.emailPlaceholder} value={dialogState.email} onChange={setEmail} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>{RequestQuoteDialogContent.details}<span style={{color: 'var(--bs-danger)'}}>*</span></Form.Label>
                            <Form.Control placeholder={RequestQuoteDialogContent.detailsPlaceholder} as="textarea" rows={3} value={dialogState.details} onChange={setDetails} required />
                        </Form.Group>
                        <div className="d-flex">
                            <button type='submit' className="btn btn-block btn-primary-solid mx-auto">
                                {RequestQuoteDialogContent.sendRequestButtonText}
                            </button>
                        </div>
                    </Form>
                }
                {dialogState.isLoading ? 
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <Spinner />
                    </div>
                : null}
            </Modal.Body>
        </Modal>
    );
});