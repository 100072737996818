import React, { useContext, useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import styles from './Header.module.css';
import logo from '../resources/icons/logo.svg';
import { Sections } from "../definitions/Sections";
import { SectionNames } from "../content/SectionNames";
import { HeaderContent } from "../content/HeaderContent";
import { RequestQuoteType } from "../services/RequestService";
import { DialogsServiceContext } from "../services/DialogsService";

import { TbDevices2, TbHome, TbMapPin } from 'react-icons/tb';
import { BsEnvelopeFill } from 'react-icons/bs';

function Header() {
    const [isScrollTop, setIsScrollTop] = useState(true);
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
    const dialogsService = useContext(DialogsServiceContext);

    const handleContactUsClick = () => {
        dialogsService?.RequestQuoteDialog?.open(RequestQuoteType.ContactUs, HeaderContent.contactWithUsButtonText);
    }

    useEffect(() => {
        const handler = () => {
            setIsScrollTop((isScrollTop) => {
                if (isScrollTop && document.documentElement.scrollTop > 5) {
                    return false;
                }

                if (!isScrollTop && document.documentElement.scrollTop < 5) {
                    return true;
                }

                return isScrollTop;
            });
        };

        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
    }, []);

    return (
        <Navbar className={`${isScrollTop ? styles.headerAtTopContainer : styles.headerContainer} ${isNavbarExpanded ? styles.navbarExpanded : ''} ${styles.navbar}`} collapseOnSelect expand="lg" fixed='top' id="navbar" onToggle={setIsNavbarExpanded}>
                <Navbar.Brand className='p-0 d-flex align-items-center'>
                    <a href="/">
                        <img src={logo} className={styles.headerLogo} alt="logo" />
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle className={styles.toggler} aria-controls="responsive-navbar-nav">
                    <div className={styles.hamburger}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </Navbar.Toggle>
                <Navbar.Collapse className={`${styles.navigation} mt-2 mt-md-0`} id="responsive-navbar-nav">
                    <Nav className="ms-auto align-items-center flex-wrap">
                        <Nav.Link className='px-lg-2' href={`/#${Sections.home}`}><TbHome /><span>{SectionNames.home}</span></Nav.Link>
                        <Nav.Link className='px-lg-2' href={`/#${Sections.services}`}><TbDevices2 /><span>{SectionNames.services}</span></Nav.Link>
                        <Nav.Link className='px-lg-2' href={`/#${Sections.contacts}`}><TbMapPin className='me-1' /><span>{SectionNames.contacts}</span></Nav.Link>    
                    </Nav>
                    <div className='d-lg-block d-flex flex-column align-items-center'>
                        <Button className={`btn-white-outline d-inline-flex align-items-center ${styles.contactUsButton}`} onClick={handleContactUsClick}><BsEnvelopeFill className="me-2" /><span>{HeaderContent.contactWithUsButtonText}</span></Button>
                        <Button className={styles.changeLocaleButton} href={HeaderContent.changeLocaleHref}>{HeaderContent.changeLocaleButtonText}</Button>
                    </div>
                </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;