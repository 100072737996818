import React, { Component, RefObject } from "react";
import { jarallax, JarallaxOptions } from "jarallax";

type JarallaxProps = {
    options: JarallaxOptions,
    className?: string,
    children?: JSX.Element | JSX.Element[];
}

export default class Jarallax extends Component<JarallaxProps> {
    el: RefObject<HTMLDivElement>
    isDestroyed = false;

    constructor(props: JarallaxProps) {
        super(props);

        this.el = React.createRef();
    }

    // init on mount.
    componentDidMount() {
        jarallax( this.el.current, this.props.options );
    }

    // destroy on unmount.
    componentWillUnmount() {
        this.isDestroyed = true;
        jarallax( this.el.current, 'destroy' );
    }

    render() {

        return (
            <div
                className={ `jarallax ${ this.props.className ?? '' }` }
                ref={ this.el }
            >
                <img className="jarallax-img" src={ this.props.options.imgSrc } alt='background' />
                {this.props.children}
            </div>
        )
    }
}