import project1Img from '../resources/projects/cnpui-654x450.png';
import project2Img from '../resources/projects/yuuwallet-624x423.png';
import project3Img from '../resources/projects/varuna-tattoo-612x408.png';
import project4Img from '../resources/projects/ekan-546x372.png';
import project5Img from '../resources/projects/peopli-screen-648x399.png';
import project6Img from '../resources/projects/ticketgroup-675x468.png';

type ProjectContentType = {
    text: string,
    imageSrc: string
}

export const ProjectsContent: ProjectContentType[][] = [
    [ // First row
        {
            text: 'Parking Enforcement system\nHectronic GmbH',
            imageSrc: project1Img
        },
        {
            text: 'Electronic wallet solution\nYuuWallet\nSingapore',
            imageSrc: project2Img
        },
        {
            text: 'SaaS CRM - the CRM for tattoo shops\nVaruna\nGermany',
            imageSrc: project3Img
        },
    ],
    [ // Second row
        {
            text: 'App to control industrial seed drying system\nEkan ASESH',
            imageSrc: project4Img
        },
        {
            text: 'Peopli App\npersonality tests',
            imageSrc: project5Img
        },
        {
            text: 'Travel to Russia portal\nTicketGroup-Russia.com',
            imageSrc: project6Img
        },
    ]
];