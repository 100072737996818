import { BsLayoutTextWindowReverse } from 'react-icons/bs';
import { SlScreenSmartphone } from 'react-icons/sl';
import { IoEarthOutline, IoWifiOutline, IoRocketOutline, IoDesktopOutline } from 'react-icons/io5';
import { IconType } from "react-icons";
import { SVGProps } from 'react';

import { ReactComponent as Phone } from '../resources/icons/phone.svg';
import { ReactComponent as Web } from '../resources/icons/web.svg';
import { ReactComponent as Globe } from '../resources/icons/globe.svg';
import { ReactComponent as Signal } from '../resources/icons/signal.svg';
import { ReactComponent as Rocket } from '../resources/icons/rocket.svg';
import { ReactComponent as Monitor } from '../resources/icons/monitor.svg';

type ServiceContentType = {
    title: string,
    text: string,
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

export const ServicesContent: ServiceContentType[][] = [
    [ // First row
        {
            title: 'Mobile apps',
            text: 'We develop native apps for Android and iOS with the help of ReactNative technology',
            icon: (props) => <Phone {...props} />
        },
        {
            title: 'WEB apps',
            text: 'We build performant WEB - apps with powerful back-end subsystem and adaptive responsive interface based on ReactJS',
            icon: (props) => <Web {...props} />
        },
        {
            title: 'SaaS services',
            text: 'We build SaaS services based on the powerful platform that works over Java VM or Node.JS with mobile and web front-end apps',
            icon: (props) => <Globe {...props} />
        }
    ],
    [ // Second row
        {
            title: 'Internet of Things (IoT)',
            text: 'We develop apps to control things over the Internet and software for electronics of smart devices',
            icon: (props) => <Signal {...props} />
        },
        {
            title: 'Embedded electronics',
            text: 'We develop software for industrial electronics and embedded controllers',
            icon: (props) => <Rocket {...props} />
        },
        {
            title: 'Enterprise software',
            text: 'We build enterprise software systems like CRM, ERP, and special-purpose systems based on your business requirements',
            icon: (props) => <Monitor {...props} />
        }
    ]
]