import client1 from '../resources/clients/bitmap-109x37.png';
import client2 from '../resources/clients/ekanlogo-69x63.png';
import client3 from '../resources/clients/expertek-118x30.png';
import client4 from '../resources/clients/group-174x35.png';
import client5 from '../resources/clients/logo-44x53.png';
import client6 from '../resources/clients/natsprom-logo-200-82x80.png';
import client7 from '../resources/clients/nextpay-83x30.png';

export const ClientsImageSrc: string[] = [
    client1,
    client2,
    client3,
    client4,
    client5,
    client6,
    client7,
]