type ConfigType = {
    apiUrl: string;
}

const DEV_config: ConfigType = {
    apiUrl: 'http://127.0.0.1:8080'
}

const PROD_config: ConfigType = {
    apiUrl: ''
}

const config = {
    ...(process.env.NODE_ENV === 'development' ? DEV_config : PROD_config)
}

export default config;