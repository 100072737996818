import config from "../config";

const LANGUAGE = 'lang=en';

export async function SendRequest(data: { type: RequestQuoteType, name: string, email: string, details: string }) {
    try {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const result = await fetch(`${config.apiUrl}/notify?${LANGUAGE}`, requestOptions);
        return result.status === 200;
    }
    catch (ex) {
        console.error(ex);
        return false;
    }
}

export enum RequestQuoteType {
    RequestAQuote = "RequestAQuote",
    ContactUs = "ContactUs"
}